<template>
  <div>
    <PageHeader title="Organimmo Import" />
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <div class="tw-px-2.5 tw-pt-5 tw-pb-16">
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="OrganimmoImportForm"
        class="tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg"
        @submit="importOrganimmoData"
      >
        <FormulateInput
          type="file"
          name="contacts"
          accepts=".csv"
          label="Contacten"
          validation="required|mime:text/csv"
          :uploader="uploadContactsFile"
          :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
          :file-class="['tw-h-10']"
          element-class="formulate-input-element formulate-input-element--file tw-mt-1 xl:tw-mt-6"
          wrapper-class="formulate-input-wrapper"
          outer-class="formulate-input"
        >
          <template #file="context">
            <BaseFileUploadDisplay
              v-bind="context"
              :show-remove-file="true"
            />
          </template>
        </FormulateInput>
        <FormulateInput
          type="file"
          name="properties"
          accepts=".csv"
          label="Panden"
          validation="required|mime:text/csv"
          :uploader="uploadPropertiesFile"
          :upload-area-mask-class="['tw-h-10 !tw-rounded-sm !tw-border-0.5']"
          :file-class="['tw-h-10']"
          element-class="formulate-input-element formulate-input-element--file tw-mt-1 xl:tw-mt-6"
          wrapper-class="formulate-input-wrapper"
          outer-class="formulate-input"
        >
          <template #file="context">
            <BaseFileUploadDisplay
              v-bind="context"
              :show-remove-file="true"
            />
          </template>
        </FormulateInput>
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Importeren
        </FormulateInput>
      </FormulateForm>
    </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'

import { uploadFileToTempStorage } from '@/services/apiService'
import { importOrganimmoProperties } from '@/services/properties'

import { successModal } from '@/modalMessages'

export default {
  name: 'OrganimmoImport',
  components: {
    PageHeader
  },
  data () {
    return {
      values: {},
      contactsFileData: null,
      propertiesFileData: null
    }
  },
  methods: {
    async importOrganimmoData () {
      const response = await importOrganimmoProperties(
        {
          contacts: this.contactsFileData,
          properties: this.propertiesFileData
        }
      )
      successModal('Organimmo data wordt in de achtergrond geïmporteerd')
      this.contactsFileData = null
      this.propertiesFileData = null
      this.values = {}
      return response
    },
    async uploadContactsFile (file, progress) {
      this.contactsFileData = await this.uploadFile(file, progress)
    },
    async uploadPropertiesFile (file, progress) {
      this.propertiesFileData = await this.uploadFile(file, progress)
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(50)
        const { url, key } = (await uploadFileToTempStorage(formData)).data
        progress(100)
        return { url, key }
      } catch (error) {
        this.$formulate.handle(error, 'confirmEditBidForm')
      }
    }
  }
}
</script>
